import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ModalBasicComponent } from 'src/app/shared/components/modal-basic/modal-basic.component';
import { Users } from 'src/app/shared/models/Users';
import { DepartmentsService } from 'src/app/shared/services/departments.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html'
})
export class ConfigurationComponent implements OnInit {

  public listUsers: Users[];
  public listUsersTemp: Users[];
  public User: Users;

  @ViewChild('usersTable') table: DatatableComponent;
  @ViewChild('modalEdit') modalEdit: ModalBasicComponent;
  @ViewChild('modalNew') modalNew: ModalBasicComponent;

  constructor(public departmentsService: DepartmentsService) { }

  ngOnInit(): void {
    this.User = {
      entity: 0,
      sid: '',
      sid_Name: '',
      email: '',
      surName: '',
      givenName: '',
      dsa: '',
      expired: '',
      role: '',
      percentBudget: 0,
      percentTravel: 0,
      isActive: true
    }

    this.onObtenerUsers();
  }

  onObtenerUsers() {
    this.departmentsService.getUsers().toPromise()
      .then(data => {
        this.listUsers = data as Users[];
        this.listUsersTemp = data as Users[];
      });
  }

  onUpdateFilter(event, filters: string[]) {
    let val = event.target.value.toLowerCase();

    // filter our data
    let filteredItems = [];

    this.listUsersTemp.forEach(item => {
      filters.forEach(filter => {
        if (item[filter] !== null && item[filter].toLowerCase().includes(val)) {
          filteredItems.push(item);
        }
      })
    })
    filteredItems = filteredItems.filter((v, i) => filteredItems.findIndex(item => item.sid == v.sid) == i);
    this.listUsers = filteredItems;

    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  onOpenModalEdit(user: Users) {
    this.modalEdit.show();
    this.User = user;
  }

  onCancelModalEdit() {
    this.modalEdit.hide();
    this.onObtenerUsers();
  }

  onOpenModalNew() {
    this.User = {
      entity: 0,
      sid: '',
      sid_Name: '',
      email: '',
      surName: '',
      givenName: '',
      dsa: '',
      expired: '',
      role: '',
      percentBudget: 0,
      percentTravel: 0,
      isActive: true
    }
    this.modalNew.show();
  }

  onCancelModalNew() {
    this.modalNew.hide();
    this.onObtenerUsers();
  }

  onGuardarUsers() {
    this.departmentsService.saveUsers(this.User).subscribe(
      () => {
        this.onCancelModalEdit();
        this.onCancelModalNew();

        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          title: 'El registro se ha guardado.'
        })
      },
      err => {
        console.log(err);
      });
  }

  onEliminarUsers(id: string) {
    Swal.fire({
      title: '¿Desea eliminar este registro?',
      text: "¡No se podrá revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, deseo eliminar el registro!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.departmentsService.deleteUsers(id).subscribe(
          () => {
            this.onObtenerUsers();

            Swal.fire(
              '¡Eliminado!',
              'El registro fue eliminado.',
              'success'
            )
          },
          err => {
            console.log(err);
          });
      }
    })
  }

  onActiveClick() {
    this.User.isActive = true;
  }

  onInactiveClick() {
    this.User.isActive = false;
  }

}
