import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ClickOutsideModule } from 'ng-click-outside';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './shared/components/accordion';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { LoginService } from './shared/services/login.service';
import { DepartmentsService } from './shared/services/departments.service';
import { BudgetsService } from './shared/services/budgets.service';
import { authInterceptorProviders } from './shared/guards/auth.interceptor';
import { MenuItems } from './shared/components/menu-items/menu-items';

import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './auth/login/login.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { CardComponent } from './shared/components/card/card.component';
import { TitleComponent } from './admin/title/title.component';
import { BreadcrumbsComponent } from './admin/breadcrumbs/breadcrumbs.component';
import { HomeComponent } from './admin/pages/home/home.component';
import { ProfileComponent } from './admin/pages/profile/profile.component';
import { ErrorComponent } from './auth/error/error.component';
import { DepartmentsComponent } from './admin/pages/departments/departments.component';
import { SettingsComponent } from './admin/pages/settings/settings.component';
import { ModalBasicComponent } from './shared/components/modal-basic/modal-basic.component';
import { TravelsComponent } from './admin/pages/travels/travels.component';
import { FilterArrBoolPipe } from './shared/pipes/filter-arr-bool.pipe';
import { FilterArrStrPipe } from './shared/pipes/filter-arr-str.pipe';
import { DetailsTravelsComponent } from './admin/pages/travels/details-travels/details-travels.component';
import { ConfigurationComponent } from './admin/pages/configuration/configuration.component';
import { ModalStaticComponent } from './shared/components/modal-static/modal-static.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AdminComponent,
    LoginComponent,
    SpinnerComponent,
    CardComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    TitleComponent,
    BreadcrumbsComponent,
    HomeComponent,
    ProfileComponent,
    ErrorComponent,
    DepartmentsComponent,
    SettingsComponent,
    ModalBasicComponent,
    TravelsComponent,
    FilterArrBoolPipe,
    FilterArrStrPipe,
    DetailsTravelsComponent,
    ConfigurationComponent,
    ModalStaticComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    SocialLoginModule,
    NgbModule,
    NgxDatatableModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [MenuItems, LoginService, DepartmentsService, BudgetsService, authInterceptorProviders,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('522545960442-c3ch7btfg97o5hqn35t3nhdedajngrnp.apps.googleusercontent.com'),
          }],
      } as SocialAuthServiceConfig
    },
    {
      provide: [PERFECT_SCROLLBAR_CONFIG],
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
