<!-- Contextual classes table starts -->
<app-card [title]="sid_Name" [classHeader]="true" [blockClass]="'table-border-style'">
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>#</th>
                    <th style="text-align: center;">Cuenta Contable</th>
                    <th>Viajes Especiales</th>
                    <th>Presupuestado</th>
                    <th>Ejecutado</th>
                    <th>Reserva</th>
                    <th>Saldo Disponible</th>
                </tr>
            </thead>
            <tbody>
                <tr [ngClass]="{'table-info':i % 2 == 0}" *ngFor="let travel of travelBudget; let i = index">
                    <th scope="row">{{i+1}}</th>
                    <td><button class="btn btn-round btn-sm" [ngClass]="onTravelBudget(travel.presupuestado)"
                            (click)="onOpenModal(travel.accountCode, travel.accountName)">{{travel.accountName}}</button>
                    </td>
                    <td style="text-align: center;" [ngStyle]="onColorValues(travel.saldoAnterior)">
                        {{travel.saldoAnterior | number:'1.2-2'}}</td>
                    <td style="text-align: center;" [ngStyle]="onColorValues(travel.presupuestado)">
                        {{travel.presupuestado | number:'1.2-2'}}</td>
                    <td style="text-align: center;" [ngStyle]="onColorValues(travel.ejecutado)">
                        {{travel.ejecutado | number:'1.2-2'}}</td>
                    <td style="text-align: center;" [ngStyle]="onColorValues(travel.reserva)">
                        {{travel.reserva | number:'1.2-2'}}</td>
                    <td style="text-align: center;"
                        [ngStyle]="onColorValues(travel.saldoAnterior+travel.presupuestado+travel.ejecutado+travel.reserva)">
                        {{travel.saldoAnterior+travel.presupuestado+travel.ejecutado+travel.reserva | number:'1.2-2'}}
                    </td>
                </tr>
                <tr>
                    <th></th>
                    <th style="text-align: center;">TOTAL</th>
                    <th style="text-align: center;"
                        [ngStyle]="onColorValues(onSumColumns(travelBudget, 'saldoAnterior'))">
                        {{onSumColumns(travelBudget, 'saldoAnterior')| number:'1.2-2'}}</th>
                    <th style="text-align: center;"
                        [ngStyle]="onColorValues(onSumColumns(travelBudget, 'presupuestado'))">
                        {{onSumColumns(travelBudget, 'presupuestado')| number:'1.2-2'}}</th>
                    <th style="text-align: center;" [ngStyle]="onColorValues(onSumColumns(travelBudget, 'ejecutado'))">
                        {{onSumColumns(travelBudget, 'ejecutado')| number:'1.2-2'}}</th>
                    <th style="text-align: center;" [ngStyle]="onColorValues(onSumColumns(travelBudget, 'reserva'))">
                        {{onSumColumns(travelBudget, 'reserva')| number:'1.2-2'}}</th>
                    <th style="text-align: center;" [ngStyle]="onColorValues(onSumRows(travelBudget))">
                        {{onSumRows(travelBudget)| number:'1.2-2'}}</th>
                </tr>
            </tbody>
        </table>
    </div>
</app-card>
<!-- Contextual classes table ends -->
<!-- modal large-->
<app-modal-basic #modalLarge [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
        <h4 class="modal-title">{{AccountName}}</h4>
        <button type="button" class="close basic-close" (click)="modalLarge.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="app-modal-body">
        <!--<div class="table-responsive">-->
        <table class="table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Fecha</th>
                    <th>Tipo Lote</th>
                    <th>Descripción</th>
                    <th>Valor</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let details of travelDetailsBudget; let i = index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{details.createDate}}</td>
                    <td>{{details.journalNumber}}</td>
                    <td>{{details.description}}</td>
                    <td [ngStyle]="onColorOpositive(details.entityValue)">{{details.entityValue | number:'1.2-2'}}</td>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>TOTAL</th>
                    <th [ngStyle]="onColorOpositive(onSumColumns(travelDetailsBudget, 'entityValue'))">
                        {{onSumColumns(travelDetailsBudget, 'entityValue')| number:'1.2-2'}}</th>
                </tr>
            </tbody>
        </table>
        <!--</div>-->
    </div>
</app-modal-basic>
<!-- End modal large-->
<div class="row">
    <div class="col-sm-6 m-auto">
        <app-card [title]="sid_Name">
            <div id="chart"></div>
        </app-card>
    </div>
</div>