import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {

  public userInfo: any;

  constructor() {
    this.userInfo = {
      sid: '',
      name: '',
      email: '',
      role: '',
      entity: ''
    }
  }

  ngOnInit() {
    this.userInfo.sid = localStorage.getItem('sid');
    this.userInfo.name = localStorage.getItem('name');
    this.userInfo.email = localStorage.getItem('email');
    this.userInfo.role = localStorage.getItem('role');
    this.userInfo.entity = localStorage.getItem('entity');
  }

}
