import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalBasicComponent } from 'src/app/shared/components/modal-basic/modal-basic.component';
import { BudgetsService } from 'src/app/shared/services/budgets.service';
import Swal from 'sweetalert2';
import * as c3 from 'c3';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html'
})
export class DepartmentsComponent implements OnInit {

  public FunctionName: string;
  public AccountName: string;
  public ParentCode: string;

  public items = {
    AccountCode: '',
    FunctionCode: ''
  }

  public actualBudget;
  public detailsBudget;

  @ViewChild('modalLarge') modal: ModalBasicComponent;

  constructor(private route: ActivatedRoute,
    private budgetService: BudgetsService) { }

  ngOnInit(): void {
    this.ParentCode = this.route.snapshot.params['code'];
    this.FunctionName = this.route.snapshot.params['code_name'];

    this.budgetService.getActualBudget(this.ParentCode).toPromise()
      .then(data => {
        this.actualBudget = data;
        this.onChartView(this.actualBudget);
      });
  }

  onBudget(n: number) {
    if (n > 0) return "btn-primary"
    else return "btn-warning";
  }

  onColorValues(n: number) {
    if (n > 0) return { 'color': '#266D07' }
    else if (n == 0) return { 'color': '#34495E' }
    else return { 'color': '#FC6180' };
  }

  onColorOpositive(n: number) {
    if (n < 0) return { 'color': '#266D07' }
    else if (n == 0) return { 'color': '#34495E' }
    else return { 'color': '#FC6180' };
  }

  onSumColumns(arr: any, property: string): number {
    if (arr) {
      let i = arr.length;
      let total = 0;
      while (i--) total += arr[i][property];
      return total
    }
  }

  onSumRows(arr: any): number {
    if (arr) {
      let i = arr.length;
      let total = 0;
      while (i--) total += arr[i].saldoAnterior + arr[i].subvencion + arr[i].presupuestado + arr[i].ejecutado + arr[i].reserva;
      return total
    }
  }

  onOpenModal(accountCode: string, accountName: string, functionCode: string) {
    this.items.AccountCode = accountCode;
    this.AccountName = accountName;
    this.items.FunctionCode = functionCode;

    this.budgetService.getDetailsBudget(this.items).toPromise()
      .then(data => {
        this.detailsBudget = data;
        if (this.detailsBudget.length > 0) {
          this.modal.show();
        }
        else {
          Swal.fire('Detalle Departamento', 'No existe detalle para esta cuenta', 'warning');
        }
      });
  }

  onChartView(presupuesto) {
    let chart = c3.generate({
      bindto: '#chart',
      data: {
        columns: [
          ['Disponible', this.onSumRows(presupuesto)],
          ['Ejecutado', (this.onSumColumns(presupuesto, 'saldoAnterior') * -1) + (this.onSumColumns(presupuesto, 'ejecutado') * -1) + (this.onSumColumns(presupuesto, 'reserva') * -1)]
        ],
        type: 'pie'
      },
      color: {
        pattern: ['#6AA84F', '#A54E47', '#00C292', '#AB8CE4']
      },
    });
  }
}
