import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Functions } from '../models/Functions';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DepartmentsService {

  listFunctions: Functions[];

  constructor(private httpClient: HttpClient) { }

  readonly BaseURI = 'https://api-presupuesto.men.uio.ec/api';

  getSettings(): Observable<any> {
    return this.httpClient.get(this.BaseURI + '/departments/settings');
  }

  getUsers(): Observable<any> {
    return this.httpClient.get(this.BaseURI + '/departments/users');
  }

  getFunctions() {
    this.httpClient.get(this.BaseURI + '/departments/functions').subscribe(
      data => {
        this.listFunctions = data as Functions[];
      },
      err => console.log(err));
  }

  saveSettings(settings) {
    return this.httpClient.post(this.BaseURI + '/departments/settings', settings);
  }

  deleteSettings(id: string) {
    return this.httpClient.delete(this.BaseURI + '/departments/settings/' + id);
  }

  saveUsers(user) {
    return this.httpClient.post(this.BaseURI + '/departments/users', user);
  }

  deleteUsers(id: string) {
    return this.httpClient.delete(this.BaseURI + '/departments/users/' + id);
  }
}
