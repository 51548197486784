<!-- profile Start -->
<div class="row">
    <div class="col-md-6 m-auto">
        <div class="card card-contact-box ">
            <div class="card-block ">
                <div class="card-contain text-center ">
                    <img src="assets/images/users/{{userInfo.sid}}.png" width="70px" alt="avatar">
                    <h6 class="f-w-600 f-20 p-b-5 m-0 p-t-15 ">{{userInfo.name}} - {{userInfo.entity}}</h6>
                    <h5 class="text-success">{{userInfo.email}}</h5>
                    <p class="text-muted p-b-25 m-0 p-t-5 ">{{userInfo.role}}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- profile End -->
</div>