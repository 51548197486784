<div class="pcoded" [attr.nav-type]="navType" [attr.theme-layout]="themeLayout" [attr.layout-type]="layoutType"
  [attr.vertical-placement]="verticalPlacement" [attr.vertical-layout]="verticalLayout"
  [attr.pcoded-device-type]="deviceType" [attr.vertical-nav-type]="verticalNavType"
  [attr.vertical-effect]="verticalEffect" [attr.vnavigation-view]="vNavigationView" (window:resize)="onResize($event)">
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <nav class="navbar header-navbar pcoded-header" [attr.pcoded-header-position]="pcodedHeaderPosition"
      [attr.header-theme]="headerTheme">
      <div class="navbar-wrapper">
        <div class="navbar-logo" [attr.logo-theme]="logoTheme">
          <a class="mobile-menu" id="mobile-collapse" href="javascript:;" (click)="toggleOpened()"
            [exclude]="'#main_navbar'" (clickOutside)="onClickedOutside($event)">
            <i class="ti-menu"></i>
          </a>
          <a [routerLink]="['/app/home']">
            <img class="img-fluid" src="assets/images/auth/app-logo-circle-white.svg" width="50px" alt="Theme-Logo" />
          </a>
          <a (click)="onMobileMenu()" class="mobile-options">
            <i class="ti-more"></i>
          </a>
        </div>

        <div class="navbar-container">
          <div>
            <ul class="nav-left">
              <li>
                <div class="sidebar_toggle"><a href="javascript:;" (click)="toggleOpened()"><i
                      class="ti-menu f-18"></i></a>
                </div>
              </li>
            </ul>

            <ul [@mobileMenuTop]="isCollapsedMobile" class="nav-right" [ngClass]="isCollapsedMobile">
              <li class="header-notification">
                <a href="javascript:;">
                  <i class="ti-bell"></i>
                  <span class="badge bg-c-pink"></span>
                </a>
                <ul class="show-notification">
                  <li>
                    <h6>Notificaciones</h6>
                  </li>
                  <li>
                    <div class="media">
                      <img class="d-flex align-self-center img-radius" src="assets/images/users/{{userInfo.sid}}.png"
                        alt="Generic placeholder image">
                      <div class="media-body">
                        <h5 class="notification-user">{{userInfo.name}}</h5>
                        <p class="notification-msg">Bienvenido a la nueva versión del sistema de Presupuestos MEN</p>
                        <span class="notification-time">Today</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>

              <li class="user-profile header-notification">
                <a href="javascript:;">
                  <img src="assets/images/users/{{userInfo.sid}}.png" class="img-radius" alt="User-Profile-Image">
                  <span>{{userInfo.name}}</span>
                  <i class="ti-angle-down"></i>
                </a>
                <ul class="show-notification profile-notification">
                  <li *ngIf="userInfo.role=='Técnico'">
                    <a [routerLink]="['/app/configuration']">
                      <i class="ti-settings"></i> Configuración
                    </a>
                  </li>
                  <li>
                    <a [routerLink]="['/app/profile']">
                      <i class="ti-user"></i> Perfil
                    </a>
                  </li>
                  <li>
                    <a (click)="onLogOut()" style="cursor: pointer;">
                      <i class="ti-layout-sidebar-left"></i> Cerrar Sesión
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <div class="pcoded-main-container" [style.margin-top]="headerFixedMargin">
      <div class="pcoded-wrapper">
        <nav id="main_navbar" class=" pcoded-navbar" (clickOutside)="onClickedOutside($event)"
          [exclude]="'#mobile-collapse'" [attr.pcoded-header-position]="pcodedHeaderPosition"
          [attr.navbar-theme]="navBarTheme" [attr.active-item-theme]="activeItemTheme" sub-item-theme="theme2"
          active-item-style="style0" [attr.pcoded-navbar-position]="pcodedSidebarPosition">
          <div class="sidebar_toggle"><a href="javascript:;"><i class="icon-close icons"></i></a></div>
          <div class="pcoded-inner-navbar main-menu" appAccordion>
            <div class="">
              <div class="main-menu-header">
                <img class="img-40 img-radius" src="assets/images/users/{{userInfo.sid}}.png" alt="User-Profile-Image">
                <div class="user-details">
                  <span>{{userInfo.name}}</span>
                  <span id="more-details" (click)="toggleOpenedSidebar()">{{userInfo.role}}<i
                      class="ti-angle-down"></i></span>
                </div>
              </div>
              <div class="main-menu-content">
                <ul>
                  <li [@mobileMenuTop]="isCollapsedSideBar" class="more-details" [ngClass]="isCollapsedSideBar">
                    <a [routerLink]="['/app/configuration']" *ngIf="userInfo.role=='Técnico'"><i
                        class="ti-settings"></i>Configuración</a>
                    <a [routerLink]="['/app/profile']"><i class="ti-user"></i>Perfil</a>
                    <a (click)="onLogOut()" style="cursor: pointer;"><i class="ti-layout-sidebar-left"></i>Cerrar
                      Sesión</a>
                  </li>
                </ul>
              </div>
            </div>

            <perfect-scrollbar [style.max-width]="'100%'" [style.max-height]="'calc(100vh - 230px)'" [config]="config">
              <div *ngFor="let asideItems of menuItems.getAll()" class="d-color">
                <div class="pcoded-navigatio-lavel" [attr.menu-title-theme]="menuTitleTheme">{{asideItems.label}}</div>
                <ul class="pcoded-item pcoded-left-item" [attr.item-border]="itemBorder"
                  [attr.item-border-style]="itemBorderStyle" [attr.subitem-border]="subItemBorder"
                  *ngFor="let asideItem of asideItems.main" appAccordionLink group="{{asideItem.state}}">

                  <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'link'" appAccordionLink
                    group="{{asideItem.state}}">
                    <a [routerLink]="['/', asideItem.main_state, asideItem.state]"
                      target="{{asideItem.target ? '_blank' : '_self'}}" appAccordionToggle
                      *ngIf="asideItem.main_state; else mainContent">
                      <span class="pcoded-micon"><i
                          class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge"
                        class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ng-template #mainContent>
                      <a [routerLink]="['/', asideItem.state]" target="{{asideItem.target ? '_blank' : '_self'}}"
                        appAccordionToggle>
                        <span class="pcoded-micon"><i
                            class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                        <span class="pcoded-mtext">{{ asideItem.name }}</span>
                        <span *ngFor="let asideBadge of asideItem.badge"
                          class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                        <span class="pcoded-mcaret"></span>
                      </a>
                    </ng-template>
                  </li>
                  <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'sub'" class="pcoded-hasmenu"
                    [attr.dropdown-icon]="dropDownIcon" [attr.subitem-icon]="subItemIcon" appAccordionLink
                    group="{{asideItem.state}}">
                    <a [routerLinkActive]="['active']" href="javascript:;" appAccordionToggle>
                      <span class="pcoded-micon"><i
                          class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge"
                        class="pcoded-badge label label-{{ asideBadge.type }}">{{asideBadge.value}}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ul class="pcoded-submenu">
                      <ng-template ngFor let-asideChildren [ngForOf]="asideItem.children">
                        <li [routerLinkActive]="['active']" *ngIf="asideChildren.type !== 'sub'">
                          <a [routerLink]="['/', asideItem.state, asideChildren.state]"
                            target="{{asideChildren.target ? '_blank' : '_self'}}">
                            <span class="pcoded-micon"><i class="ti-angle-right"></i></span>
                            <span class="pcoded-mtext">{{ asideChildren.name }} </span>
                            <span *ngFor="let asideChildrenBadge of asideChildren.badge"
                              class="pcoded-badge label label-{{ asideChildrenBadge.type }}">{{asideChildrenBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                        </li>

                        <li [routerLinkActive]="['active']" class="pcoded-hasmenu" [attr.dropdown-icon]="dropDownIcon"
                          [attr.subitem-icon]="subItemIcon" *ngIf="asideChildren.type === 'sub'" appAccordionLink
                          group="sub-toggled">
                          <a href="javascript:;" appAccordionToggle>
                            <span class="pcoded-micon"><i class="ti-direction-alt"></i></span>
                            <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                            <span *ngFor="let asideChildrenBadge of asideChildren.badge"
                              class="pcoded-badge label label-{{ asideChildrenBadge.type }}">{{asideChildrenBadge.value}}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                          <ul class="pcoded-submenu">
                            <ng-template ngFor let-asideChildrenSub [ngForOf]="asideChildren.children">
                              <li [routerLinkActive]="['active']">
                                <a [routerLink]="['/', asideItem.state, asideChildren.state, asideChildrenSub.state]"
                                  target="{{asideChildrenSub.target ? '_blank' : '_self'}}">
                                  <span class="pcoded-micon"><i class="ti-angle-right"></i></span>
                                  <span class="pcoded-mtext">{{ asideChildrenSub.name }}</span>
                                  <span *ngFor="let asideChildrenSubBadge of asideChildrenSub.badge"
                                    class="pcoded-badge label label-{{ asideChildrenSubBadge.type }}">{{asideChildrenSubBadge.value}}</span>
                                  <span class="pcoded-mcaret"></span>
                                </a>
                              </li>
                            </ng-template>
                          </ul>
                        </li>
                      </ng-template>
                    </ul>
                  </li>
                </ul>
              </div>
            </perfect-scrollbar>
          </div>
        </nav>
        <div class="pcoded-content">
          <div class="pcoded-inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <app-title></app-title>
                <app-breadcrumbs></app-breadcrumbs>
                <div class="page-body">
                  <router-outlet>
                    <app-spinner></app-spinner>
                  </router-outlet>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>