<div class="row" style="background-color:darkslategrey; padding-top: 20px;">
  <!-- Card Feature Start -->
  <div class="col-md-6 col-xl-3" *ngFor="let setting of listSettings">
    <div class="card feature-card-box">
      <div class="card-block-small text-center"
        [routerLink]="['/app/departments/', setting.code, setting.code_Name]" style="cursor: pointer;">
        <div class="feature-icon" [ngStyle]="onStyleDpto(setting.code, 'color')">
          <i [ngClass]="onStyleDpto(setting.code, 'icon')"></i>
        </div>
        <p class="f-w-100 m-b-10 m-t-15">{{setting.code_Name}}</p>
      </div>
    </div>
  </div>
  <!-- Card Feature End -->
</div>