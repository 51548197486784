import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUADM = [
  {
    label: 'MENU',
    main: [
      {
        main_state:'app',
        state: 'home',
        short_label: 'H',
        name: 'Inicio',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'app',
        short_label: 'C',
        name: 'Configuración',
        type: 'sub',
        icon: 'ti-plug',
        children: [
          {
            state: 'settings',
            name: 'Asignación Departamentos'
          },
        ]
      },
      {
        main_state:'app',
        state: 'travels',
        short_label: 'T',
        name: 'Presupuesto de Viajes',
        type: 'link',
        icon: 'ti-car'
      },
    ],
  }
];

const MENUSR = [
  {
    label: 'MENU',
    main: [
      {
        main_state:'app',
        state: 'home',
        short_label: 'H',
        name: 'Inicio',
        type: 'link',
        icon: 'ti-home'
      },
      {
        main_state:'app',
        state: 'travels',
        short_label: 'T',
        name: 'Presupuesto de Viajes',
        type: 'link',
        icon: 'ti-car'
      },
    ],
  }
];

@Injectable()
export class MenuItems {
  public role: string;

  constructor() {
    this.role = localStorage.getItem('role');
  }

  getAll(): Menu[] {
    if (this.role == 'Técnico') return MENUADM;
    else return MENUSR;
  }
}
