import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class LoginService {

  constructor(private httpClient: HttpClient) { }

  readonly BaseURI = 'https://api-presupuesto.men.uio.ec/api';

  login(userLogin) {
    return this.httpClient.post(this.BaseURI + '/login', userLogin);
  }
}
