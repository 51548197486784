import { LoginService } from '../../shared/services/login.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SocialUser, SocialAuthService, GoogleLoginProvider } from "angularx-social-login";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  loginUser: SocialUser;

  constructor(private userService: LoginService,
    private router: Router,
    private googleAuth: SocialAuthService) { }

  ngOnInit(): void {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');

    if (localStorage.getItem('token') != null)
      this.router.navigateByUrl('/app/home');
  }

  signInWithGoogle(): void {
    this.googleAuth.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      res => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        this.loginUser = res;

        this.userService.login(this.loginUser).subscribe(
          (res: any) => {
            if (res.token) {
              localStorage.setItem('token', res.token);
              localStorage.setItem('name', this.loginUser.name);
              localStorage.setItem('email', this.loginUser.email);
              localStorage.setItem('entity', res.entity);
              localStorage.setItem('role', res.role);
              localStorage.setItem('sid', res.sid);
              localStorage.setItem('dsa', res.dsa);
              localStorage.setItem('expired', res.expired);
              this.router.navigateByUrl('/app/home');
            }
            else {
              Toast.fire({
                icon: 'error',
                title: res.msgAlerta
              })
              this.router.navigateByUrl('/auth/error');
            }
          },
          err => {
            console.log(err);
          }
        );
      }
    ).finally(() => {
      this.googleAuth.signOut();
    });
  }
}
