<section class="login p-fixed d-flex text-center common-img-bg bg-primary">
    <div class="auth-fluid">
        <div class="row">
            <div class="col-ms-12">
                <div class="m-b-40">
                    <a [routerLink]="['/auth/login']" class="logo-link" title="Volver a Login">
                        <img src="assets/images/auth/app-logo.svg" class="logo" width="200px" alt="Logo" />
                    </a>
                </div>
                <h1>No está autorizado para ingresar a este sitio.</h1>
                <p>Sus credenciales no están registradas.
                    <br> Consulte con el administrador TI del campo.
                </p>
            </div>
        </div>
    </div>
</section>