<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <div class="auth-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="login-card card-body auth-body">
          <form class="md-float-material">
            <div class="text-center">
              <img src="assets/images/auth/app-logo-circle-white.svg" width="100px" alt="logo">
              <h4 class="text-muted-login">Presupuesto MEN</h4>
            </div>
            <div class="auth-box">
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-left txt-primary">Inicie sesión con una cuenta Google</h3>
                  <img src="assets/images/auth/google.png" alt="Logo Google">
                </div>
              </div>
              <hr />

              <!-- <div class="row m-t-40 m-b-40">
                <div class="col-sm-12">
                  <img src="assets/images/auth/google.png" alt="Logo Google">
                </div>
              </div> -->

              <div class="row">
                <div class="col-md-12">
                  <button type="submit" (click)=signInWithGoogle()
                    class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Ingresar con Google</button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-6">
                  <img src="assets/images/auth/app-logo.svg" alt="small-logo">
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>