import { Component, OnInit } from '@angular/core';
import { Settings } from 'src/app/shared/models/Settings';
import { DepartmentsService } from 'src/app/shared/services/departments.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  public listSettings: Settings[];

  constructor(private departmentsService: DepartmentsService) { }

  ngOnInit(): void {
    this.departmentsService.getSettings().toPromise()
      .then(data => {
        this.listSettings = data as Settings[];
        this.onActualizaSettings();
      });
  }

  onActualizaSettings() {
    let filterSettings = this.listSettings.filter(i => i.sid == localStorage.getItem('sid') && i.year == new Date().getFullYear().toString());
    this.listSettings = filterSettings;
  }

  onStyleDpto(value, type: string) {
    switch (value) {
      //ADRA
      case '11401':
        if (type == 'color') return { 'background-color': ' #1d8348 ' }
        else return { 'icofont icofont-unity-hand': true };

      //AFAM
      case '11402':
        if (type == 'color') return { 'background-color': '#ec7063' }
        else return { 'icofont icofont-users-alt-4': true };

      //ASOCIACIÓN MINISTERIAL
      case '11403':
        if (type == 'color') return { 'background-color': '#f36b2c' }
        else return { 'icofont icofont-fire-alt': true };

      //AVENTUREROS
      case '11404':
        if (type == 'color') return { 'background-color': '#f39c12' }
        else return { 'icofont icofont-abc': true };

      //COMUNICACIÓN
      case '11405':
        if (type == 'color') return { 'background-color': '#60a1f0' }
        else return { 'icofont icofont-video-cam': true };

      //CONQUISTADORES
      case '11406':
        if (type == 'color') return { 'background-color': '#a40b0b' }
        else return { 'icofont icofont-ui-fire-wall': true };

      //EDUCACIÓN
      case '11407':
        if (type == 'color') return { 'background-color': '#0095b6' }
        else return { 'icofont icofont-hat': true };

      //ESCUELA SABÁTICA
      case '11408':
        if (type == 'color') return { 'background-color': '#008080' }
        else return { 'icofont icofont-read-book': true };

      //EVANGELISMO
      case '11409':
        if (type == 'color') return { 'background-color': '#854442' }
        else return { 'icofont icofont-read-book-alt': true };

      //JÓVENES ADVENTISTAS
      case '11410':
        if (type == 'color') return { 'background-color': '#5e3c58' }
        return { 'icofont icofont-users-social': true };

      //LIBERTAD RELIGIOSA
      case '11411':
        if (type == 'color') return { 'background-color': '#9e379f' }
        return { 'icofont icofont-woman-bird': true };

      //MINISTERIO DEL ADOLESCENTE
      case '11412':
        if (type == 'color') return { 'background-color': '#00cc2c' }
        return { 'icofont icofont-student-alt': true };

      //MINISTERIO DEL NIÑO
      case '11413':
        if (type == 'color') return { 'background-color': '#390060' }
        return { 'icofont icofont-boy': true };

      //MINISTERIO DE LA FAMILIA
      case '11414':
        if (type == 'color') return { 'background-color': '#e39cf7' }
        return { 'icofont icofont-group-students': true };

      //MINISTERIO DE LA MUJER
      case '11415':
        if (type == 'color') return { 'background-color': '#f01d6a' }
        return { 'icofont icofont-businesswoman': true };

      //MINISTERIO DE LA SALUD
      case '11417':
        if (type == 'color') return { 'background-color': '#797d7f' }
        return { 'icofont icofont-bed-patient': true };

      //MINISTERIO PERSONAL
      case '11418':
        if (type == 'color') return { 'background-color': '#444444' }
        return { 'icofont icofont-lawyer-alt-2': true };

      //MISIÓN GLOBAL
      case '11419':
        if (type == 'color') return { 'background-color': '#5fddc1' }
        return { 'icofont icofont-earth': true };

      //MAYORDOMÍA
      case '11420':
        if (type == 'color') return { 'background-color': '#f1c40f' }
        return { 'icofont icofont-bank': true };

      //PUBLICACONES
      case '11422':
        if (type == 'color') return { 'background-color': '#e59866' }
        return { 'icofont icofont-briefcase-alt-1': true };

      //JURÍDICO
      case '39716':
        if (type == 'color') return { 'background-color': '#03396c' }
        return { 'icofont icofont-law-alt-2': true };

      //PRESIDENCIA
      case '39911':
        if (type == 'color') return { 'background-color': '#536878' }
        return { 'icofont icofont-throne': true };

      //SECRETARÍA
      case '39931':
        if (type == 'color') return { 'background-color': '#0095b6' }
        return { 'icofont icofont-id-card': true };

      //TESORERÍA
      case '39951':
        if (type == 'color') return { 'background-color': '#7986cb' }
        return { 'icofont icofont-money': true };

      //PROYECTO CALEB
      case '115811':
        if (type == 'color') return { 'background-color': '#347474' }
        return { 'icofont icofont-holding-hands': true };

      //PROYECTO 1 AÑO EN MISIÓN
      case '115891':
        if (type == 'color') return { 'background-color': '#00204a' }
        return { 'icofont icofont-gym': true };

      //ENTRENAMIENTO DE PASTORES
      case '1165121':
        if (type == 'color') return { 'background-color': '#81cfdb' }
        return { 'icofont icofont-graduate-alt': true };

      //SUBVENCIÓN ESPECIAL NUEVAS IGLESIAS
      case '1182121':
        if (type == 'color') return { 'background-color': '#e57074' }
        return { 'icofont icofont-building-alt': true };

      default:
        break;
    }
  }

}
