<div class="row" *ngIf="(role=='Administrador' || role=='Técnico') && !isVisible" style="background-color:lightslategrey; padding-top: 20px;">
    <div class="col-md-4 col-xl-4"
        *ngFor="let user of listUsers | filterArrBool: true: ['isActive']">
        <div class="card client-map" style="cursor: pointer;"
            (click)="onShowDetailsTravels(user.sid_Name, user.dsa, user.expired)">
            <div class="card-block">
                <div class="client-detail">
                    <div class="client-profile">
                        <img src="assets/images/users/{{user.sid}}.png" width="85px" height="100px" alt="user">
                    </div>
                    <div class="client-contain">
                        <h5>{{user.sid_Name}}</h5>
                        <p class="text-muted">{{user.email}}</p>
                        <p class="text-custom m-0 p-t-10" style="font-weight: bold;">{{user.role}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isVisible">
    <app-details-travels [sid_Name]="sid_Name" [dsa]="dsa" [expired]="expired"></app-details-travels>
</div>

<div class="row" *ngIf="(role=='Administrador' || role=='Técnico') && isVisible">
    <div class="col-md-4">
        <button class="btn btn-primary btn-round" (click)="onReturnTravels()"><i
                class="fa fa-reply-all"></i>Volver</button>
    </div>
</div>