import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BudgetsService {

    constructor(private httpClient: HttpClient) { }

    readonly BaseURI = 'https://api-presupuesto.men.uio.ec/api';

    getActualBudget(parentCode: string): Observable<any> {
        return this.httpClient.get(this.BaseURI + '/budget/actual/' + parentCode);
    }

    getDetailsBudget(details): Observable<any> {
        return this.httpClient.post(this.BaseURI + '/budget/details', details);
    }

    getTravelBudget(inputs): Observable<any> {
        return this.httpClient.post(this.BaseURI + '/travel/actual', inputs);
    }

    getTravelDetailsBudget(details): Observable<any> {
        return this.httpClient.post(this.BaseURI + '/travel/details', details);
    }
}