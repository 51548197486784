import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-static',
  templateUrl: './modal-static.component.html'
})
export class ModalStaticComponent implements OnInit {

  @Input() dialogClass: string;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  public visible = false;
  public visibleAnimate = false;

  constructor() { }

  ngOnInit() {

  }

  public show(): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }

}
