import { DepartmentsService } from 'src/app/shared/services/departments.service';
import { Settings } from 'src/app/shared/models/Settings';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Users } from 'src/app/shared/models/Users';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

  public settingData: Settings;
  public listSettings: Settings[];
  public listSettingsTemp: Settings[];
  public listUsers: Users[];
  public users: any;
  public functions: any;

  @ViewChild('settingsTable') table: DatatableComponent;

  constructor(public departmentsService: DepartmentsService) {
    this.settingData = {
      id: '',
      sid: '',
      sid_Name: '',
      code: '',
      code_Name: '',
      year: new Date().getFullYear().toString()
    };
  }

  ngOnInit(): void {
    this.departmentsService.getFunctions();
    this.departmentsService.getUsers().toPromise()
      .then(data => {
        this.listUsers = data as Users[];
      }
      );
    this.onObtenerSettings();
  }

  onObtenerSettings() {
    this.departmentsService.getSettings().toPromise()
      .then(data => {
        this.listSettings = data as Settings[];
        this.listSettingsTemp = data as Settings[];
      });
  }

  onEliminarSettings(id: string) {

    Swal.fire({
      title: '¿Desea eliminar este registro?',
      text: "¡No se podrá revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, deseo eliminar el registro!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.departmentsService.deleteSettings(id).subscribe(
          () => {
            this.onObtenerSettings();

            Swal.fire(
              '¡Eliminado!',
              'El registro fue eliminado.',
              'success'
            )
          },
          err => {
            console.log(err);
          });
      }
    })
  }

  onGuardarSettings() {
    this.settingData.id = this.users.sid + this.functions.code + this.settingData.year;
    this.settingData.sid = this.users.sid;
    this.settingData.sid_Name = this.users.sid_Name;
    this.settingData.code = this.functions.code;
    this.settingData.code_Name = this.functions.code_Name;
    this.departmentsService.saveSettings(this.settingData).subscribe(
      () => {
        this.onObtenerSettings();

        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          title: 'El registro se ha guardado.'
        })
      },
      err => {
        console.log(err);
      });
  }

  onUpdateFilter(event, filters: string[]) {
    let val = event.target.value.toLowerCase();

    // filter our data
    let filteredItems = [];

    this.listSettingsTemp.forEach(item => {
      filters.forEach(filter => {
        if (item[filter] !== null && item[filter].toLowerCase().includes(val)) {
          filteredItems.push(item);
        }
      })
    })
    filteredItems = filteredItems.filter((v, i) => filteredItems.findIndex(item => item.id == v.id) == i);
    this.listSettings = filteredItems;

    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
