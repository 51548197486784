import { Component, OnInit } from '@angular/core';
import { Users } from 'src/app/shared/models/Users';
import { DepartmentsService } from 'src/app/shared/services/departments.service';

@Component({
  selector: 'app-travels',
  templateUrl: './travels.component.html'
})
export class TravelsComponent implements OnInit {

  public role: string;
  public isVisible = false;
  public sid_Name: string;
  public dsa: string;
  public expired: string;
  public listUsers: Users[];

  constructor(public departmentsService: DepartmentsService) { }

  ngOnInit(): void {
    this.departmentsService.getUsers().toPromise()
      .then(data => {
        this.listUsers = data as Users[];
      });
    this.role = localStorage.getItem('role');

    if (this.role == "Departamental") {
      this.isVisible = true;
      this.sid_Name = localStorage.getItem('name');
      this.dsa = localStorage.getItem('dsa');
      this.expired = localStorage.getItem('expired');
    }
  }

  onShowDetailsTravels(sid_Name: string, dsa: string, expired: string) {
    this.isVisible = true;
    this.sid_Name = sid_Name;
    this.dsa = dsa;
    this.expired = expired
  }

  onReturnTravels() {
    this.isVisible = false;
  }

}
