import { AuthGuard } from './shared/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './admin/pages/home/home.component';
import { ProfileComponent } from './admin/pages/profile/profile.component';
import { ErrorComponent } from './auth/error/error.component';
import { SettingsComponent } from './admin/pages/settings/settings.component';
import { DepartmentsComponent } from './admin/pages/departments/departments.component';
import { TravelsComponent } from './admin/pages/travels/travels.component';
import { ConfigurationComponent } from './admin/pages/configuration/configuration.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'auth', redirectTo: '/auth/login', pathMatch: 'full' },
  {
    path: 'auth', component: AuthComponent,
    children: [
      {
        path: 'login', component: LoginComponent,
        data: {
          breadcrumb: 'Login'
        }
      },
      {
        path: 'error', component: ErrorComponent,
        data: {
          breadcrumb: 'Error'
        }
      }
    ]
  },
  {
    path: 'app',
    component: AdminComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'home', component: HomeComponent,
        data: {
          breadcrumb: 'Inicio',
          icon: 'icofont-home bg-c-blue',
          status: false
        }
      },
      {
        path: 'profile', component: ProfileComponent,
        data: {
          breadcrumb: 'Perfil',
          icon: 'icofont-business-man bg-c-green',
          breadcrumb_caption: 'Perfil de Usuario',
          status: true
        }
      },
      {
        path: 'settings', component: SettingsComponent,
        data: {
          breadcrumb: 'Asignaciones',
          icon: 'icofont-shield-alt bg-c-green',
          breadcrumb_caption: 'Asignación de Departamentos',
          status: true
        }
      },
      {
        path: 'departments/:code/:code_name', component: DepartmentsComponent,
        data: {
          breadcrumb: 'Departamento',
          icon: 'icofont-coins bg-c-green',
          breadcrumb_caption: 'Detalle Departamento',
          status: true
        }
      },
      {
        path: 'travels', component: TravelsComponent,
        data: {
          breadcrumb: 'Viajes',
          icon: 'icofont-airplane bg-c-green',
          breadcrumb_caption: 'Presupuesto de Viajes',
          status: true
        }
      },
      {
        path: 'configuration', component: ConfigurationComponent,
        data: {
          breadcrumb: 'Configuración',
          icon: 'icofont icofont-settings-alt bg-c-green',
          breadcrumb_caption: 'Detalles de configuración',
          status: true
        }
      }
    ]
  },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
