import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArrBool'
})
export class FilterArrBoolPipe implements PipeTransform {

  transform(items: any[], searchBool: boolean, filters: string[]): any[] {
    if (!items) return [];

    let filteredItems = [];

    items.forEach(item => {
      filters.forEach((filter: any) => {
        if (item[filter] !== null && item[filter] == searchBool) {
          filteredItems.push(item);
        }
      })
    })
    
    return filteredItems;
  }

}
