<div class="row">
    <div class="col-sm-12">
        <!-- Tooltip Validation card start -->
        <div class="card">
            <div class="card-header">
                <h5>Asignación</h5>
            </div>
            <div class="card-block">
                <form #settingForm="ngForm" (ngSubmit)="onGuardarSettings()">
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Departamental</label>
                        <div class="col-sm-4">
                            <select name="departamental" class="form-control" [(ngModel)]="users" required>
                                <option *ngFor="let users of listUsers" [ngValue]="users">
                                    {{users.sid_Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Departamento</label>
                        <div class="col-sm-4">
                            <select name="departamento" class="form-control" [(ngModel)]="functions" required>
                                <option *ngFor="let functions of departmentsService.listFunctions"
                                    [ngValue]="functions">
                                    {{functions.code_Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-sm-2"></label>
                        <div class="col-sm-6">
                            <button type="submit" class="btn btn-success m-b-0"
                                [disabled]="settingForm.invalid">Registar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- Tooltip Validation card end -->
</div>

<div class="row">
    <div class="col-sm-12">
        <!-- Contextual classes table starts -->
        <app-card [title]="'Departamentos asignados a Departamentales'" [classHeader]="true"
            [blockClass]="'table-border-style'">

            <div class="row">
                <div class="col-sm-12">
                    <label class="form-inline f-left">
                        <div class="form-group">
                            <label>Buscar: </label>
                            <input type="text" class="form-control input-sm m-l-10" placeholder="Nombre / Departamento"
                                spellcheck="false" (keyup)="onUpdateFilter($event, ['sid_Name', 'code_Name'])" />
                        </div>
                    </label>
                </div>
            </div>
            <ngx-datatable #settingsTable class='data-table' [rows]="listSettings" [columnMode]="'flex'"
                [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10" [scrollbarH]="true"
                [sort]="[{prop: 'sid_Name', dir: 'asc'}]">

                <ngx-datatable-column name="ID" prop="id" [flexGrow]="0.5" [minWidth]="200">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.id}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Nombre" prop="sid_Name" [flexGrow]="0.4" [minWidth]="140">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.sid_Name}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="ID Dpto" [sortable]="false" [flexGrow]="0.2" [minWidth]="90">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.code}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Departamento" prop="code_Name" [flexGrow]="0.5" [minWidth]="150">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.code_Name}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Acciones" [sortable]="false" prop="id"
                    [headerClass]="'ngx-datable-columns-center'" [cellClass]="'ngx-datable-columns-center'"
                    [flexGrow]="0.3" [minWidth]="140">
                    <ng-template let-row="row" ngx-datatable-cell-template>

                        <button class="btn btn-danger btn-sm" (click)="onEliminarSettings(row.id)">
                            <i class="fa fa-trash"></i>Eliminar</button>

                    </ng-template>
                </ngx-datatable-column>

            </ngx-datatable>
        </app-card>
        <!-- Contextual classes table ends -->
    </div>
</div>