<div class="row">
    <div class="col-sm-12">
        <!-- Contextual classes table starts -->
        <app-card [title]="'Datos de usuarios registrados'" [classHeader]="true" [blockClass]="'table-border-style'">

            <div class="row">
                <div class="col-sm-9">
                    <label class="form-inline f-left">
                        <div class="form-group">
                            <label>Buscar: </label>
                            <input type="text" class="form-control input-sm m-l-10" placeholder="ID / Nombre"
                                spellcheck="false" (keyup)="onUpdateFilter($event, ['sid_Name', 'sid'])" />
                        </div>
                    </label>
                </div>
                <div class="col-sm-3 text-right">
                    <button class="btn btn-warning btn-icon" ngbTooltip="Nuevo" placement="left"
                        (click)="onOpenModalNew()">
                        <i class="fa fa-user-plus"></i></button>
                </div>
            </div>
            <ngx-datatable #usersTable class="data-table" [rows]="listUsers" [columnMode]="'flex'" [headerHeight]="45"
                [footerHeight]="50" [scrollbarH]="true" [rowHeight]="'auto'" [sort]="[{prop: 'sid_Name', dir: 'asc'}]">

                <ngx-datatable-column name="CI" prop="sid" [flexGrow]="0.3" [minWidth]="120">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.sid}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Nombre" prop="sid_Name" [flexGrow]="0.5" [minWidth]="160">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.sid_Name}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Email" prop="email" [sortable]="false" [flexGrow]="0.7" [minWidth]="270">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.email}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Estado" prop="isActive" [headerClass]="'ngx-datable-columns-center'"
                    [cellClass]="'ngx-datable-columns-center'" [flexGrow]="0.3" [minWidth]="70">
                    <ng-template let-row="row" ngx-datatable-cell-template>

                        <input type="checkbox" [(ngModel)]="row.isActive" [disabled]="true">

                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Acciones" [sortable]="false" [flexGrow]="0.4" [minWidth]="130"
                    [headerClass]="'ngx-datable-columns-center'" [cellClass]="'ngx-datable-columns-center'">
                    <ng-template let-row="row" ngx-datatable-cell-template>

                        <button class="btn btn-primary btn-icon m-r-5" ngbTooltip="Editar" placement="right"
                            (click)="onOpenModalEdit(row)">
                            <i class="fa fa-edit"></i></button>
                        <button class="btn btn-danger btn-icon" ngbTooltip="Eliminar" placement="left"
                            (click)="onEliminarUsers(row.sid)">
                            <i class="fa fa-trash"></i></button>

                    </ng-template>
                </ngx-datatable-column>

            </ngx-datatable>
        </app-card>
        <!-- Contextual classes table ends -->
    </div>
</div>

<!-- modal Edit-->
<app-modal-static #modalEdit [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
        <h4 class="modal-title">Editar Usuario</h4>
        <button type="button" class="close basic-close" (click)="onCancelModalEdit()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="app-modal-body">
        <form #formEdit="ngForm">
            <div class="form-group row">
                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':entity.invalid}">Entidad</label>
                <div class="col-sm-4">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-barcode"></i></label></span>
                        <input type="number" class="form-control" name="entity" #entity="ngModel" placeholder="Entidad"
                            [(ngModel)]="User.entity" required>
                    </div>
                </div>

                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':sid.invalid}">CI</label>
                <div class="col-sm-4">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-id-card"></i></label></span>
                        <input type="text" class="form-control" name="sid" #sid="ngModel" maxlength="10"
                            placeholder="CI" [(ngModel)]="User.sid" required>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':givenName.invalid}">Nombre</label>
                <div class="col-sm-4">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-business-man"></i></label></span>
                        <input type="text" class="form-control" name="givenName" #givenName="ngModel"
                            placeholder="Nombre" [(ngModel)]="User.givenName" required>
                    </div>
                </div>

                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':surName.invalid}">Apellido</label>
                <div class="col-sm-4">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-business-man"></i></label></span>
                        <input type="text" class="form-control" name="surName" #surName="ngModel" placeholder="Apellido"
                            [(ngModel)]="User.surName" required>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':email.invalid}">Email</label>
                <div class="col-sm-6">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-ui-message"></i></label></span>
                        <input type="email" class="form-control" name="email" #email="ngModel" placeholder="Email"
                            [(ngModel)]="User.email" required>
                    </div>
                </div>

                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':role.invalid}">Rol</label>
                <div class="col-sm-4">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-chair"></i></label></span>
                        <input type="text" class="form-control" name="role" #role="ngModel" placeholder="Rol"
                            [(ngModel)]="User.role" required>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':dsa.invalid}">Code-Emp</label>
                <div class="col-sm-3">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-caret-right"></i></label></span>
                        <input type="text" class="form-control" name="dsa" #dsa="ngModel" maxlength="3"
                            placeholder="Cod. Emp" [(ngModel)]="User.dsa" required>
                    </div>
                </div>

                <label class="col-sm-2 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':expired.invalid}">Code-ExEmp</label>
                <div class="col-sm-3">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-caret-left"></i></label></span>
                        <input type="text" class="form-control" name="expired" #expired="ngModel" maxlength="3"
                            placeholder="Cod. ExEmp" [(ngModel)]="User.expired" required>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold">% Prspto</label>
                <div class="col-sm-3">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-numbered"></i></label></span>
                        <input type="number" class="form-control" name="percentBudget" #percentBudget="ngModel"
                            placeholder="%Presupuesto" [(ngModel)]="User.percentBudget" required>
                    </div>
                </div>

                <label class="col-sm-2 col-form-label font-weight-bold">% Viajes</label>
                <div class="col-sm-3">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-numbered"></i></label></span>
                        <input type="number" class="form-control" name="percentTravel" #percentTravel="ngModel"
                            placeholder="%Viajes" [(ngModel)]="User.percentTravel" required>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="User.isActive">
                <label class="col-sm-2 font-weight-bold">Estado</label>
                <div class="col-sm-9">
                    <div class="form-radio">
                        <div class="radio radio-primary radio-inline">
                            <label>
                                <input type="radio" name="status" checked='true' (click)="onActiveClick()">
                                <i class="helper"></i>Activo
                            </label>
                        </div>
                        <div class="radio radio-primary radio-inline">
                            <label>
                                <input type="radio" name="status" (click)="onInactiveClick()">
                                <i class="helper"></i>Inactivo
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="!User.isActive">
                <label class="col-sm-2 font-weight-bold">Estado</label>
                <div class="col-sm-9">
                    <div class="form-radio">
                        <div class="radio radio-primary radio-inline">
                            <label>
                                <input type="radio" name="status" (click)="onActiveClick()">
                                <i class="helper"></i>Activo
                            </label>
                        </div>
                        <div class="radio radio-primary radio-inline">
                            <label>
                                <input type="radio" name="status" checked='true' (click)="onInactiveClick()">
                                <i class="helper"></i>Inactivo
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary ripple m-r-5" (click)="onCancelModalEdit()"><i
                class="fa fa-sign-out"></i>Cerrar</button>
        <button type="submit" class="btn btn-success ripple light" [disabled]="formEdit.invalid"
            (click)="onGuardarUsers()"><i class="fa fa-save"></i>Guardar</button>
    </div>
</app-modal-static>
<!-- End modal Edit-->

<!-- modal New-->
<app-modal-static #modalNew [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
        <h4 class="modal-title">Nuevo Usuario</h4>
        <button type="button" class="close basic-close" (click)="onCancelModalNew()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="app-modal-body">
        <form #formNew="ngForm">
            <div class="form-group row">
                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':entity.invalid}">Entidad</label>
                <div class="col-sm-4">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text">
                                <i class="icofont icofont-barcode"></i></label></span>
                        <input type="number" class="form-control" name="entity" #entity="ngModel" placeholder="Entidad"
                            [(ngModel)]="User.entity" required>
                    </div>
                </div>

                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':sid.invalid}">CI</label>
                <div class="col-sm-4">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-id-card"></i></label></span>
                        <input type="text" class="form-control" name="sid" #sid="ngModel" maxlength="10"
                            placeholder="CI" [(ngModel)]="User.sid" required>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':givenName.invalid}">Nombre</label>
                <div class="col-sm-4">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-business-man"></i></label></span>
                        <input type="text" class="form-control" name="givenName" #givenName="ngModel"
                            placeholder="Nombre" [(ngModel)]="User.givenName" required>
                    </div>
                </div>

                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':surName.invalid}">Apellido</label>
                <div class="col-sm-4">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-business-man"></i></label></span>
                        <input type="text" class="form-control" name="surName" #surName="ngModel" placeholder="Apellido"
                            [(ngModel)]="User.surName" required>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':email.invalid}">Email</label>
                <div class="col-sm-6">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-ui-message"></i></label></span>
                        <input type="email" class="form-control" name="email" #email="ngModel" placeholder="Email"
                            [(ngModel)]="User.email" required>
                    </div>
                </div>

                <label class="col-sm-1 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':role.invalid}">Rol</label>
                <div class="col-sm-4">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-chair"></i></label></span>
                        <input type="text" class="form-control" name="role" #role="ngModel" placeholder="Rol"
                            [(ngModel)]="User.role" required>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':dsa.invalid}">Code-Emp</label>
                <div class="col-sm-3">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-caret-right"></i></label></span>
                        <input type="text" class="form-control" name="dsa" #dsa="ngModel" maxlength="3"
                            placeholder="Cod. Emp" [(ngModel)]="User.dsa" required>
                    </div>
                </div>

                <label class="col-sm-2 col-form-label font-weight-bold"
                    [ngClass]="{'text-danger':expired.invalid}">Code-ExEmp</label>
                <div class="col-sm-3">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-caret-left"></i></label></span>
                        <input type="text" class="form-control" name="expired" #expired="ngModel" maxlength="3"
                            placeholder="Cod. ExEmp" [(ngModel)]="User.expired" required>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label font-weight-bold">% Prspto</label>
                <div class="col-sm-3">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-numbered"></i></label></span>
                        <input type="number" class="form-control" name="percentBudget" #percentBudget="ngModel"
                            placeholder="%Presupuesto" [(ngModel)]="User.percentBudget" required>
                    </div>
                </div>

                <label class="col-sm-2 col-form-label font-weight-bold">% Viajes</label>
                <div class="col-sm-3">
                    <div class="input-group input-group-sm input-group-inverse">
                        <span class="input-group-prepend"><label class="input-group-text"><i
                                    class="icofont icofont-numbered"></i></label></span>
                        <input type="number" class="form-control" name="percentTravel" #percentTravel="ngModel"
                            placeholder="%Viajes" [(ngModel)]="User.percentTravel" required>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 font-weight-bold">Estado</label>
                <div class="col-sm-9">
                    <div class="form-radio">
                        <div class="radio radio-primary radio-inline">
                            <label>
                                <input type="radio" name="status" checked='true' (click)="onActiveClick()">
                                <i class="helper"></i>Activo
                            </label>
                        </div>
                        <div class="radio radio-primary radio-inline">
                            <label>
                                <input type="radio" name="status" (click)="onInactiveClick()">
                                <i class="helper"></i>Inactivo
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary ripple m-r-5" (click)="onCancelModalNew()"><i
                class="fa fa-sign-out"></i>Cerrar</button>
        <button type="submit" class="btn btn-success ripple light" [disabled]="formNew.invalid"
            (click)="onGuardarUsers()"><i class="fa fa-save"></i>Guardar</button>
    </div>
</app-modal-static>
<!-- End modal New-->