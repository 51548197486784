import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BudgetsService } from 'src/app/shared/services/budgets.service';
import Swal from 'sweetalert2';
import * as c3 from 'c3';
import { ModalBasicComponent } from 'src/app/shared/components/modal-basic/modal-basic.component';

@Component({
  selector: 'app-details-travels',
  templateUrl: './details-travels.component.html'
})
export class DetailsTravelsComponent implements OnInit {

  @Input() sid_Name: string;
  @Input() dsa: string;
  @Input() expired: string;
  @ViewChild('modalLarge') modal: ModalBasicComponent;

  public AccountName: string;
  public travelBudget;
  public travelDetailsBudget;

  public items = {
    AccountCode: '',
    CodeEmp: '',
    CodeEmpAnt: ''
  }

  constructor(public budgetService: BudgetsService) { }

  ngOnInit(): void {
    this.items.CodeEmp = this.dsa;
    this.items.CodeEmpAnt = this.expired;

    this.budgetService.getTravelBudget(this.items).toPromise()
      .then(data => {
        this.travelBudget = data;
        this.onChartView(this.travelBudget);
      });
  }

  onTravelBudget(n: number) {
    if (n > 0) return "btn-primary"
    else return "btn-warning";
  }

  onColorValues(n: number) {
    if (n > 0) return { 'color': '#266D07' }
    else if (n == 0) return { 'color': '#34495E' }
    else return { 'color': '#FC6180' };
  }

  onColorOpositive(n: number) {
    if (n < 0) return { 'color': '#266D07' }
    else if (n == 0) return { 'color': '#34495E' }
    else return { 'color': '#FC6180' };
  }

  onSumColumns(arr: any, property: string): number {
    if (arr) {
      let i = arr.length;
      let total = 0;
      while (i--) total += arr[i][property];
      return total
    }
  }

  onSumRows(arr: any): number {
    if (arr) {
      let i = arr.length;
      let total = 0;
      while (i--) total += arr[i].saldoAnterior + arr[i].presupuestado + arr[i].ejecutado + arr[i].reserva;
      return total
    }
  }

  onOpenModal(accountCode: string, accountName: string) {
    this.items.AccountCode = accountCode;
    this.AccountName = accountName;

    this.budgetService.getTravelDetailsBudget(this.items).toPromise()
      .then(data => {
        this.travelDetailsBudget = data;
        if (this.travelDetailsBudget.length > 0) {
          this.modal.show();
        }
        else {
          Swal.fire('Detalle Viajes', 'No existe detalle para esta cuenta', 'warning');
        }
      });
  }

  onChartView(presupuesto) {
    let chart = c3.generate({
      bindto: '#chart',
      data: {
        columns: [
          ['Disponible', this.onSumRows(presupuesto)],
          ['Ejecutado', (this.onSumColumns(presupuesto, 'saldoAnterior') * -1) + (this.onSumColumns(presupuesto, 'ejecutado') * -1) + (this.onSumColumns(presupuesto, 'reserva') * -1)]
        ],
        type: 'pie'
      },
      color: {
        pattern: ['#6AA84F', '#A54E47', '#00C292', '#AB8CE4']
      },
    });
  }

}
